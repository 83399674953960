import axios from "axios";

export const companyFile = async (file, fileName, companyId) => {
  try {
    if (file.size > 20000000) {
      throw new Error("File size must be less than 20MB");
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    formData.append("companyId", companyId);

    const response = await axios.post(
      `https://aslis-buoy.herokuapp.com/buckets/starfish/companyfile/`,
      // `http://localhost:3002/buckets/starfish/companyfile/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("Upload Success! ", response.data.message);

    return {
      success: true,
      message: response.data.message,
      data: response.data,
      fileUrl: response.data.fileUrl,
    };
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export const staffPhoto = async (params) => {
  try {
    const { file, fileName, email = "" } = params;
    if (file.size > 5000000) {
      throw new Error("File size must be less than 5MB");
    }

    const allowedTypes = ["image/jpeg", "image/png"];
    if (!allowedTypes.includes(file.type)) {
      throw new Error(
        "Invalid file type. Only JPEG and PNG files are allowed."
      );
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    formData.append("email", email);

    const response = await axios.post(
      `https://aslis-buoy.herokuapp.com/buckets/starfish/staffphoto/`,
      // `http://localhost:3002/buckets/starfish/staffphoto/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("Upload Success! ", response.data.message);

    return {
      success: true,
      message: response.data.message,
      data: response.data,
      fileUrl: response.data.fileUrl,
    };
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export const misc = async (params) => {
  try {
    const { file, fileName } = params;
    if (file.size > 20000000) {
      throw new Error("File size must be less than 20MB");
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);

    const response = await axios.post(
      `https://aslis-buoy.herokuapp.com/buckets/starfish/misc/`,
      // `http://localhost:3002/buckets/starfish/misc/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("Upload Success! ", response.data.message);

    return {
      success: true,
      message: response.data.message,
      data: response.data,
      fileUrl: response.data.fileUrl,
    };
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export const credForm = async (params) => {
  try {
    const { file, fileName, credId = "" } = params;
    if (file.size > 20000000) {
      throw new Error("File size must be less than 20MB");
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    formData.append("credId", credId);

    const response = await axios.post(
      `https://aslis-buoy.herokuapp.com/buckets/starfish/credform/`,
      // `http://localhost:3002/buckets/starfish/credform/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("Upload Success! ", response.data.message);

    return {
      success: true,
      message: response.data.message,
      data: response.data,
      fileUrl: response.data.fileUrl,
    };
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};
