import PostGem_DEPRECATED from "../Bridge/jewelerClient/PostGem_DEPRECATED";
import { action } from "mobx";
import { WiseGem } from "../Bridge/jewelerClient/WiseGem";
import { BaseJewel } from "./BaseJewel";
import {
  companyFile,
  staffPhoto,
  misc,
  credForm,
} from "./oFileUploadFunctions";

export default class oFileUpload extends BaseJewel {
  gems = {
    upload: new PostGem_DEPRECATED({}),
    staffPhoto: new PostGem_DEPRECATED({}),
    uploadMisc: new PostGem_DEPRECATED({}),
    uploadCompanyFile: new PostGem_DEPRECATED({}),
  };

  // @action Upload = (params) => {
  //   console.log("GENERIC UPLOAD PARAMS: ", params);
  //   return this.gems.upload.Post(params);
  // };

  @action Upload = async (params) => {
    const result = await credForm(params);
    return result;
  };

  // @action UploadStaffPhoto = (params) => {
  //   return this.gems.staffPhoto.Post(params);
  // };

  @action UploadStaffPhoto = async (params) => {
    const result = await staffPhoto(params);
    return result;
  };

  // @action UploadMisc = (params) => {
  //   console.log("MISC PARAMS: ", params);
  //   return this.gems.uploadMisc.Post(params);
  // };

  @action UploadMisc = async (params) => {
    const result = await misc(params);
    return result;
  };

  // @action UploadCompanyFile = async (file, fileName, companyId) => {
  //   console.log("MONKEY:", file, fileName, companyId);
  //   return await this.gems.uploadCompanyFile.Post({
  //     file: file,
  //     fileName: fileName,
  //     companyId: companyId,
  //   });
  // };
  @action UploadCompanyFile = async (file, fileName, companyId) => {
    const result = await companyFile(file, fileName, companyId);
    return result;
  };
}
