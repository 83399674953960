import { Document, Page, pdfjs } from 'react-pdf';
import { useState } from 'react';
import styled from 'styled-components';

pdfjs.GlobalWorkerOptions.workerSrc =
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.min.js';

const ModalPdfViewer = ({ url }) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoad = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <PdfContainer>
      <Document
        file={url}
        onLoadSuccess={onDocumentLoad}
      >
        {Array.from(new Array(numPages), (_el, index) => (
          <StyledPage
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ))}
      </Document>
    </PdfContainer>
  );
};

export default ModalPdfViewer;

const PdfContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const StyledPage = styled(Page)`
  border: black 2px solid;
`;
